$primary-color: var(--primary-color);
$text-color: var(--text-color);
$background-color: var(--background-color);
$border-color: var(--border-color);

#editor {
  display: block !important;
}

.title-input {
  font-size: 1.3em !important;
  font-weight: bold !important;
  width: 100% !important;
  font-family: "Noto Serif JP", roboto !important;

  @media (max-width: 600px) {
    font-size: 1.2em !important;
  }
}

.ql-editor,
article {
  overflow-y: auto !important;
  max-width: 720px !important;
  line-height: 1.7em !important;
  width: 100% !important;
  word-break: break-word !important; /* 長い単語を改行する */
  word-wrap: break-word !important; /* 長い単語を改行する */
  white-space: normal !important; /* 改行されるように設定 */

  .ql-code-block-container {
    margin-bottom: 1em !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 1.6em !important;
    margin-bottom: 1em !important;
    font-family: "Noto Serif JP", roboto !important;
    padding-bottom: 0.2em !important;
    border-bottom: 1px solid $border-color !important;
    line-height: normal;

    @media (max-width: 600px) {
      &:nth-of-type(1) {
        font-size: 1.3em !important;
      }
      &:nth-of-type(2) {
        font-size: 1.2em !important;
      }
      &:nth-of-type(3) {
        font-size: 1.1em !important;
      }
      &:nth-of-type(4) {
        font-size: 1em !important;
      }
      &:nth-of-type(5) {
        font-size: 0.9em !important;
      }
    }
  }

  p,
  span {
    font-family: "Noto Sans JP", Roboto, "Noto Serif JP", serif !important;
  }

  p {
    margin-bottom: 1em !important;
  }

  span {
    color: $text-color;
    background-color: transparent !important;
  }

  u {
    text-decoration: none !important;
    background: linear-gradient(transparent 60%, rgba(#00b3ff, 0.4) 60%) !important;
  }

  a {
    color: #4287f5 !important;
    text-decoration: none !important;
  }

  ol,
  ul {
    font-family: "Noto Sans JP", Roboto, "Noto Serif JP", serif !important;
    margin: 0.8em 0 !important;
    list-style-type: none !important;

    li {
      margin: 0.4em 0 !important;

      > .ql-ui {
        position: absolute !important;

        &:before {
          display: inline-block !important;
          opacity: 0.8 !important;
          width: 1.2em !important;
        }
      }

      span {
        margin-left: -0.5em !important;
      }

      &[data-list="ordered"] {
        counter-increment: list-0 !important;

        > .ql-ui:before {
          content: counter(list-0, decimal) ". " !important;
          color: $primary-color !important;
        }

        &.ql-indent-1 {
          counter-increment: list-1 !important;

          > .ql-ui:before {
            content: counter(list-1, decimal) ". " !important;
          }
        }

        &.ql-indent-2 {
          counter-increment: list-2 !important;

          > .ql-ui:before {
            content: counter(list-2, decimal) ". " !important;
          }
        }

        &.ql-indent-3 {
          counter-increment: list-3 !important;

          > .ql-ui:before {
            content: counter(list-3, decimal) ". " !important;
          }
        }
      }

      &[data-list="bullet"] {
        > .ql-ui:before {
          content: "●" !important;
          color: $primary-color !important;
          font-size: 1em !important;
          scale: 0.5 !important;
        }
      }
    }

    li > .ql-ui:before {
      margin-left: -1.5em !important;
      margin-right: 0.3em !important;
      text-align: right !important;
      white-space: nowrap !important;
    }
  }

  .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em !important;
  }

  .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em !important;
  }

  .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em !important;
  }

  @media (max-width: 600px) {
    .ql-indent-1:not(.ql-direction-rtl) {
      padding-left: 1.5em !important;
    }
    .ql-indent-2:not(.ql-direction-rtl) {
      padding-left: 3em !important;
    }
    .ql-indent-3:not(.ql-direction-rtl) {
      padding-left: 4.5em !important;
    }
  }

  table {
    overflow: auto !important;
    width: 100% !important;
    border-collapse: collapse !important;
    table-layout: auto !important;
    margin-bottom: 1em !important;

    tr {
      &:nth-child(odd) {
        background-color: rgba(150, 150, 150, 0.08) !important;
      }

      &:hover {
        background-color: rgba(150, 150, 150, 0.16) !important;
      }

      &:first-child {
        font-weight: bold !important;
      }
    }

    td {
      padding: 0.4em 0.8em !important;

      p {
        margin: 0 !important;
      }
    }
  }

  .link-card {
    display: flex !important;
    flex-direction: row !important;
    border: 1px solid $border-color !important;
    border-radius: 4px !important;
    overflow: hidden !important;
    text-decoration: none !important;
    color: inherit !important;
    margin: 10px 0 !important;
    width: 100% !important;
    transition: all 0.3s ease !important;

    &:hover .link-card-title {
      color: $primary-color !important;
    }

    a {
      display: flex !important;
      align-items: center !important;
      flex-direction: row !important;
      width: 100% !important;
      height: 100% !important;
      text-decoration: none !important;

      &:hover {
        text-decoration: none !important;
      }
    }

    .link-card-image {
      display: flex !important;
      width: 270px !important;
      height: 140px !important;
      background-size: cover !important;
      background-position: center !important;
    }

    .link-card-content {
      padding: 10px !important;
      width: 100% !important;

      .link-card-title {
        font-size: 16px !important;
        font-weight: bold !important;
        margin: 0 !important;
        text-decoration: none !important;
        display: -webkit-box !important;
        -webkit-line-clamp: 1 !important;
        -webkit-box-orient: vertical !important;
        overflow: hidden !important;
      }

      .link-card-description {
        font-size: 14px !important;
        color: $text-color !important;
        margin: 5px 0 0 !important;
        text-decoration: none !important;
        display: -webkit-box !important;
        -webkit-line-clamp: 2 !important;
        line-clamp: 2 !important;
        -webkit-box-orient: vertical !important;
        overflow: hidden !important;
      }

      .link-card-domain {
        font-size: 12px !important;
        color: $text-color !important;
        margin: 5px 0 0 !important;
        text-decoration: none !important;
        text-align: right !important;
      }
    }

    @media (max-width: 600px) {
      flex-direction: column !important;

      a {
        flex-direction: column !important;
      }

      img {
        max-width: 100% !important;
        height: auto !important;
      }
    }
  }
}

.ql-editor ol {
  padding-left: 8px !important;
}

article ol {
  padding-left: 32px !important;
}

.ql-container {
  &.ql-snow {
    border: none !important;
  }

  height: auto !important;
}

.ql-toolbar {
  &.ql-snow {
    border: none !important;

    .ql-table-button-disabled {
      background: transparent !important;
      border-radius: 4px !important;
    }
  }

  .ql-picker-label {
    white-space: nowrap !important;
    color: $text-color !important;
  }

  .ql-fill {
    fill: $text-color !important;
  }

  .ql-stroke {
    stroke: $text-color !important;

    &.ql-fill {
      fill: $text-color !important;
    }
  }

  .ql-picker {
    .ql-picker-options {
      color: $text-color !important;
      background-color: $background-color !important;
      border: none !important;
    }

    .ql-even {
      color: $text-color !important;
    }
  }

  .ql-editor {
    code {
      background-color: transparent !important;
    }

    img {
      border-radius: 4px !important;
    }
  }
}

.ql-header {
  min-width: 130px !important;
  width: 160px !important;
  color: $text-color !important;
}

.ql-align-button:hover {
  background-color: #f0f0f0 !important; /* ホバー時の背景色 */
}

.qlbt-operation-menu {
  background-color: $background-color !important;
  border-radius: 4px !important;
  padding: 8px !important;
  margin: 0 4px !important;
  cursor: pointer !important;
  width: 300px !important;

  span {
    margin: 5px !important;
  }

  .qlbt-operation-menu-item {
    display: flex !important;
    align-items: center !important;
    padding: 1px !important;

    .qlbt-operation-menu-item-icon {
      margin-right: 8px !important;
    }
  }
}

.ql-snow {
  .ql-tooltip {
    border: 1px solid $border-color !important;
    background-color: $background-color !important;
    color: $text-color !important;
    border-radius: 4px !important;
    padding: 16px 32px !important;

    input[type="text"] {
      border: none !important;
      background-color: $background-color !important;
      color: $text-color !important;
    }

    .ql-action {
      color: $primary-color !important;
    }
  }
}

.ql-table-dropdown,
.ql-table-properties-form {
  color: rgba(0, 0, 0, 0.87) !important;
}

.ql-table-properties-form {
  display: none !important;
}

iframe,
.video-embed {
  margin: 0 auto !important;
  display: block !important;
  margin-bottom: 16px !important;
}

.instagram-embed {
  margin-bottom: 16px !important;
}

.twitter-embed {
  border: 1px solid $border-color;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px !important;

  .twitter-embed-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .twitter-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 8px;
      color: white;

      svg {
        width: 24px;
        height: 24px;
        fill: white;
      }
    }

    .twitter-content {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      .twitter-name {
        font-weight: bold;
        font-size: 1rem;
      }

      .twitter-date {
        font-size: 0.85rem;
        color: #657786;
      }
    }
  }

  .twitter-footer {
    text-align: right;
    font-size: 0.75rem;
    color: #657786;
  }
}
